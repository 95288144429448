.header-search {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    position: absolute;
    transition: opacity 0.24s ease-in;
    margin: 0;
    z-index: 9999;
    height: 0;
    width: 0;
    top: 105px;
    left: 0;
    right: 0;
    bottom: 0;
    @media only screen and (min-width: 767px) {
        top: 0;
    }

}

.header-search.open {
    opacity: 1;
    width: 100vw;
    height: 100%;
}


.header-search__container {
    transform: translateY(-90rem);
    background-color: #fff;
    padding: 1em 1.5em;
    margin: 0;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
}
@media only screen and (min-width: 767px) {
    .header-search__container {
        padding: 2em 4em;
    }
}

.header-search.open > .header-search__container {
    transform: translateY(0);
}

.header-search__form {
    width: 85%;
    margin: 0;
}

@media only screen and (min-width: 767px) {
    .header-search__form {
        width: 64%;
    }
}

.header-search__form-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
}


.header-search__form-group input[type="search"] {
    margin: 0 1.6rem 0 0;
    padding: 0.6em 0;
    width: calc(100% - 60px);
    height: 4rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    color: #262626;
    border-bottom: 2px solid #ccc;
    border-top: none;
    border-left: none;
    border-right: none;
    background-image: none;
    background-repeat: no-repeat;
    background-position: 0 8px;
}
@media only screen and (min-width: 767px) {
    .header-search__form-group input[type="search"] {
        width: calc(100% - 70px);
        padding-left: 2em;
        background-image: url('https://s3.amazonaws.com/assets.mshanken.com/sys/wsbolt/images/svg/search-icon2.svg');
    }
}


.header-search__form-group button[type="submit"] {
    background-color: #8D0004;
    color: white;
    height: 4rem;
    border: 1px solid #8D0004;
    border-radius: 2px;
    width: 60px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
@media only screen and (min-width: 767px) {
    .header-search__form-group button[type="submit"] {
        border-radius: 8px;
        width: 70px;
    }
}


.header-search__close {
    display: none;
}
@media only screen and (min-width: 767px) {
    .header-search__close {
        display: inline-block;
        position: absolute;
        top: 2rem;
        right: 7rem;
    }
}

.header-search__close-link {
    display: inline-block; // for IE
}

/* ensuring that the target of JS event listener is the <a> parent */
.header-search__close-link svg {
    pointer-events: none;
}
