.site-navigation {
    display: none;
    @media only screen and (min-width: 767px) {
        display: block;
        padding-bottom: 2.4rem;
        text-align: center;
    }
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin: 0 16px;
        }
    }

    a {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
    }
}