/* Site Header */

.site-header {
	padding-top: 3.2rem;
	padding-bottom: 2.4rem;
	position: fixed;
	width: 100%;
	z-index: 9997;
	@media only screen and (min-width: 767px) {
		position: relative;
		width: 100%;
		z-index: inherit;
	}
}

.site-header__layout {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	max-width: 1300px;
	margin: 0 auto;
	padding: 0;
}

.site-header__hamburger,
.site-header__logo,
.site-header__controls {
	flex: 1;
}

.site-header__hamburger a,
.site-header__controls a {
	display: inline-block;
}
.menu-icon__link svg {
	width: 25px;
	height: 23px;
	@media only screen and (min-width: 767px) {
		width: 32px;
		height: 22px;
	}
}

/* ensuring that the targets of JS event listeners are the <a> parent */
.menu-icon__link svg,
.search-icon__link svg {
    pointer-events: none;
}

.site-header__logo {
  max-width: 18rem;
}
@media only screen and (min-width: 767px) {
	.site-header__logo {
		max-width: 259px;
	}
}

.site-header__controls {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;
}

.site-header__controls a:not(:last-child) {
	margin-right: 3.2rem;
}

.site-header__controls .button,
.site-header__controls .dropdown-container {
		display: none;
}

@media only screen and (min-width: 780px) {
	.site-header__controls .button {
		display: inline-block;
    font-size: 1.3rem;
    padding: .72em 1em;
    color: #161616;
	}
	.site-header__controls .dropdown-container {
		display: inline-block;
	}
}