.site-footer {
  padding-top: 4.8rem;
  padding-bottom: 4.4rem;
}

.site-footer__layout {
  max-width: 1155px;
  margin: 0 auto;
  display: flex;
	flex-flow: row wrap;
  justify-content: space-between;
  padding: 20px;
}

.site-footer h3 {
  margin: 0 0 1.6rem;
  font-size: 2rem;
}

.site-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 4.8rem;
}

.site-footer li {
  margin-bottom: 0.8rem;
}

.site-footer li:last-of-type {
  margin-bottom: 0;
}

.site-footer a {
  color: rgba(255, 255, 255, .85);
  text-decoration: none;
}

.site-footer__copyright {
  text-align: center;
}

.site-footer .social-links li {
  display: inline-block;
}


.site-footer .social-links li + li {
  margin-left: 6px;
}

.site-footer .sister-publications img {
  max-width: 200px;
  margin-bottom: 24px;
}