/* Global */

html {
    font-size: 62.5%; /* set base font-size to 10 (16 * .625) to make rems easier to calculate */
}

body {
    font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.35;
    font-size: 1.6rem;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    display: block;
}


.ad-strip {
    text-align: center;
    img {
        display: inline;
        text-align: center;
    }
}

/* This proably doens't need to be added to the vertical ads and just to the header ads */
.ad-strip {
    padding: 16px 0;
}
@media only screen and (min-width: 1024px) {
    .ad-strip {
        padding: 32px 0;
    }
}

.ad-strip.vertical {
    padding: 0;
}

a {
    color: #8D0004;
}

figure {
    margin: 0;
}

/* Background  */

.background-alpha {
    background-color: #8D0004;
    color: white;
}


/* Buttons */

.button {
    display: inline-block;
    background: white;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    border: none;
    font-weight: bold;
    padding: .9em 1.25em; /* EM's so padding is relative to font-size */
    border-radius: .25em;
    color: #8B0724;
    text-decoration: none;
}

.button--inverse {
    background: #8D0004;
    color: white;
}

/* Utility Classes */

.u-display-block {
    display: block;
}

.underline {
    text-decoration: underline;
}



/* ethor - Start refined classes */

/* Layout */

.container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.container--side-padding {
    width: 93.75%;
}

/* TODO Need to refactor a bit. */
.flex-grid {
    display: flex;
    flex-flow: row wrap;
}

.flex-grid--gutter {
    box-sizing: border-box;
    margin-left: -32px;
}

.flex-grid--gutter .flex-grid__item {
    box-sizing: border-box;
    padding-left: 32px;

}

.flex-grid__item--flex-none {
    flex: none;
}

.flex-grid__item--flex-1 {
    flex: 1 1 0;
}

.flex-grid__item--flex-3 {
    flex: 3 1 0;
}

.flex-grid__item--flex-2 {
    flex: 2 1 0;
}

/* Going to add super specific class names we will refactor them later  */

/* On Section Front Pages with Index content  */
.flex-grid__item--index-content {
    flex: 1 0 100%;
}


@media only screen and (min-width: 1024px) {

    .flex-grid__item--index-content {
        flex: 1;
    }
}



// Adjusting main-layout for fixed site-header on mobile...
.main-layout {
	position: relative;
	padding-top: 105px;
	@media only screen and (min-width: 767px) {
		padding-top: 0;
	}
}


/* That tripple thing right after the featured posts on most section fronts */

.flex-grid.vary-row {
    justify-content: center
}

@media only screen and (min-width: 1024px) {

    .flex-grid__item--side-stack {
        flex: 1;
    }

    .flex-grid__item--side-stack-large {
        flex: 2;
    }

}

@media only screen and (min-width: 1024px) {
    .flex-grid__item--homepage-hero {
        flex: 1;
    }

    .flex-grid__item--homepage-featured-side-stack {
        flex: 1;
    }

}

/* Those three rows of sidestacks on wine-and-culture and food  dr vinny */

.flex-grid.tripple-stack {
    justify-content: space-evenly;
    flex-flow: row wrap;
}

.flex-grid.tripple-stack .flex-grid__item {
    flex: 1 0 100%;
    min-width: 300px;
}

@media only screen and (min-width: 672px) {
    .flex-grid.tripple-stack .flex-grid__item {
        flex: 1 0 50%;
    }
}

@media only screen and (min-width: 1024px) {
    .flex-grid.tripple-stack .flex-grid__item {
        flex: 1 0 33.33%;
    }
}


/* Tablet */
@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .trade-news-columns {
        display: flex;
        box-sizing: border-box;
        margin-left: -24px;
    }

    .trade-news-columns .trade-news-column {
        box-sizing: border-box;
        padding-left: 24px;
        margin-left: 0;
    }

    .trade-news-columns > * {
        flex: 1 1 0;
    }

    .trade-news-column .section-front-trade-news-feed {
        margin-bottom: 24px;
    }

    .trade-news-columns .island--border {
        border: none;
    }

    .trade-news-columns h3 {
        font-size: 18px !important;
    }

    .trade-news-columns .card__buffer.card__buffer {
        border-bottom: 1px solid #CCCCCC !important;
        margin-bottom: 12.5px !important;
        padding-bottom: 12.5px !important;
    }


    .trade-news-columns .flex-text,
    .trade-news-columns .flex-logo {
        flex: 1 1 100%;
        padding-left: 0;

    }
}

.trade-news-header {
    margin-top: 32px;
}

@media only screen and (max-width: 671px) {
    .trade-news-header {
        margin-top: 24px;
    }
    .trade-news-columns {
        display: flex;
        box-sizing: border-box;
        margin-left: -24px;
        flex-flow: row wrap;
    }

    .trade-news-columns img {
        width: 100%;
    }

    .trade-news-columns .trade-news-column {
        box-sizing: border-box;
        padding-left: 24px;
        margin-left: 0;
    }

    .trade-news-columns > * {
        flex: 1 1 100%;
    }

    .trade-news-column .section-front-trade-news-feed {
        margin-bottom: 24px;
    }

    .trade-news-columns .island--border {
        border: none;
    }

    .trade-news-columns h3 {
        font-size: 18px !important;
    }

    .trade-news-columns .card__buffer.card__buffer {
        border-bottom: 1px solid #CCCCCC !important;
        margin-bottom: 12.5px !important;
        padding-bottom: 12.5px !important;
    }


    .trade-news-columns .flex-text,
    .trade-news-columns .flex-logo {
        flex: 1 1 100%;
        padding-left: 0;

    }
}

@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .for-the-trade-grid > *  {
        flex: 1 1 50%;
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 671px) {
    .for-the-trade-grid > *  {
        flex: 1 1 100%;
        margin-bottom: 24px;
    }

    .for-the-trade-grid .card__buffer.card__buffer {
        border-bottom: none !important;
        margin-bottom: 8px !important;
        padding-bottom: 8px !important;
    }
}

.flex-text {
    flex: 4;
}

.flex-logo {
    flex: 5;
}

/*
@media only screen and (min-width: 672px) {
    .flex-grid.tripple-stack {
        justify-content: space-between;
    }
}

.flex-grid.tripple-stack  .flex-grid__item {
    flex: 1 0 100%;
    max-width: 412px;

}

@media only screen and (min-width: 672px) {
    .flex-grid.tripple-stack  .flex-grid__item {
        flex: 1 0 50%;
    }
} */


/* @media only screen and (min-width: 1024px) {
    .flex-grid.tripple-stack  .flex-grid__item {
        flex: 1 0 33.33%;
    }
} */

/* Custom grid stuff for dr vinny */

.custom-dr-vinny-grid .flex-grid__item:nth-child(1) {
    order: 2;
}

@media only screen and (min-width: 1024px) {
    .custom-dr-vinny-grid .flex-grid__item:nth-child(1) {
        order: 1;
    }
}

.custom-dr-vinny-grid .flex-grid__item:nth-child(2) {
    order: 1;
}

@media only screen and (min-width: 1024px) {
    .custom-dr-vinny-grid .flex-grid__item:nth-child(2) {
        order: 2;
    }
}

.custom-dr-vinny-grid .flex-grid__item:nth-child(3) {
    order: 3;
}

/* Custom Grid Stuff for homepage featured section */
.flex-grid.homepage {
    justify-content: center;
}

/* Used accross pages. Used to seperate out sections of pages */

.island {
    margin-bottom: 24px;

}

/* TODO: Temp style to deal with werid sketch stuff */
.island--werid {
    margin-bottom: 0;
}

@media only screen and (min-width: 672px) {
    .island--werid {
        margin-bottom: 32px;
    }
}


.island--border {
    padding-bottom: 32px;
    border-bottom: 1px solid #DDDDDD;
}

.island--double {
    margin-bottom: 64px;
}


@media only screen and (min-width: 672px) {

    .island {
        margin-bottom: 32px;
    }



    .island--border {
        padding-bottom: 32px;
        border-bottom: 1px solid #DDDDDD;
    }
}

@media only screen and (min-width: 1024px) {
    .island--double {
        margin-bottom: 64px;
    }
}

.island--border-desktop {
    padding: 0;
    border: none;
}

@media only screen and (min-width: 1024px) {
    .island--border-desktop {
        border-bottom: 1px solid #DDDDDD;
    }
}

/* Section Front Styles */
.section-front-intro-title {
    font-family: lora;
    color: #262626;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 16px;
}


.section-front-intro-body {
    color: #404040;
    font-family: open sans;
}

.section-front-intro-body p {
    margin-top: 0;
}

/* Temporary Classes */

.temp-paged-cards .card.indexer:last-of-type .card__buffer {
    border-bottom: 0;
    margin-bottom: 0;
}


.vary-responsive-ad {
    margin-top: 24px
}

@media only screen and (max-width: 672px) {
    .vary-responsive-ad {
        display: none;
    }
}


@media only screen and (min-width: 672px) {
    .vary-responsive-ad {
        margin-top: 0px;
    }
}

@media only screen and (min-width: 1024px) {
    .vary-responsive-ad {
        display: none;
    }
}

.vary-responsive-ad-desktop {
    display: none
}

@media only screen and (min-width: 1024px) {
    .vary-responsive-ad-desktop {
        display: block;
    }
}



@media only screen and (max-width: 671px) {
    .ad-strictly-tablet {
        display: none;
    }
}

@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .ad-strictly-tablet {
        display: block;
        padding: 24px 0;
        background: #F3F3F3;

    }
}
@media only screen and (min-width: 1024px) {
    .ad-strictly-tablet {
        display: none;
    }
}

.ad-strictly-mobile {
    background: #F3F3F3;
    padding: 16px 0;
}


@media only screen and (min-width: 672px) {
    .ad-strictly-mobile {
        display: none;
    }
}

.ad-constant-strip {
    text-align: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background:#f3f3f3;
}

@media only screen and (min-width: 672px) {
    .ad-constant-strip {
        margin-bottom: 32px;
    }
}

@media only screen and (max-width: 671px) {
    .hide-on-mobile {
        display: none;
    }
}

.only-tablet {
    display: none;
}

@media only screen and (min-width: 672px) {
    .only-tablet {
        display: block;
    }
}

@media only screen and (min-width: 1024px) {
    .only-tablet {
        display: none;
    }
}

.flex-grid-wine-iq-boxes {
    flex-flow: row wrap;
}

@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .flex-grid-wine-iq-boxes .odd-rectangle {
        flex: 1 0 100%;
        margin-bottom: 32px;
    }
}

@media only screen and (max-width: 671px) {
    .odd-rectangle .content-block.rectangle .content-block__grid {
        // background: red;
        display: block;
    }

    .odd-rectangle .content-block.rectangle .content-block__figure {
        margin-bottom: 12px;
    }

    .odd-rectangle .content-block.rectangle .content-block__title,
    .odd-rectangle .content-block.rectangle .content-block__subtitle {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .flex-grid-wine-iq-boxes .flex-grid__item {
        flex: 0 0 100%;
        margin-bottom: 16px;
    }
}

.dr-vinny-hero {
    width: 100%;
    flex: 100%;
}

.dr-vinny-hero img {
    width: 100%;
}

.dr-vinny-hero .card__buffer {
    border: none !important;
}

@media only screen and (min-width: 1024px) {
    .dr-vinny-hero {
        max-width: 900px;
        flex: 3 1 0;
    }

}

@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .dr-vinny-hero .card__title {
        font-size: 24px;

    }

    .dr-vinny-hero .card__subtitle {
        font-size: 16px;
    }

    .dr-vinny-hero .card__date {
        font-size: 13px;
    }
}

@media only screen and (min-width: 1024px) {
    .dr-vinny-hero-list-header {
        flex: 0 0 300px;
        min-width:300px;
    }
}



.desktop-only {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .desktop-only {
        display: block;
    }
}



/* RESPONSIVE UTITLIES */
.hidden-mobile {
    display: none;
}
@media only screen and (min-width: 767px) {
    .hidden-mobile {
        display: block;
    }
}

.visible-mobile {
    display: block;
}
@media only screen and (min-width: 767px) {
    .visible-mobile {
        display: none;
    }
}
@media only screen and (min-width: 1024px) {
    .no-desktop {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    .for-the-trade-grid-2 .content-block.rectangle .content-block__grid {
        flex-flow: row wrap;
    }

    .for-the-trade-grid-2 .content-block__figure {
        margin-bottom: 16px !important;
    }

    .for-the-trade-grid-2 .content-block__figure img {
        width: 100%;
    }

    .for-the-trade-grid-2 .content-block.rectangle .content-block__grid-item--figure,
    .for-the-trade-grid-2 .content-block.rectangle .content-block__grid-item--body {
        flex: 1 1 100%;
    }
}

@media only screen and (max-width: 1023px) {
    .for-the-trade-grid-2 .content-block__title {
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 671px) {
    .for-the-trade-grid-2 > * {
        flex: 1 1 100%;
        margin-bottom: 24px;
    }
    .for-the-trade-grid-2 .content-block__title {
        font-size: 18px !important;
    }
}


.for-the-trade-grid-3 {
    flex-flow: row wrap;
}

.for-the-trade-grid-3 > * {
    flex: 1 1 33.333%;
    margin-bottom: 24px;
}

@media only screen and (max-width: 1023px) {

    .for-the-trade-grid-3 {
        flex-flow: row wrap;
    }

    .for-the-trade-grid-3 > * {
        flex: 1 1 50%;
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 671px) {
    .for-the-trade-grid-3 > * {
        flex: 1 1 100%;
        margin-bottom: 24px;
    }
}


/* Island that has marign on tablet and desktop, but none on mobile */
@media only screen and (max-width: 671px) {
    .island--bleed {
        margin-bottom: 0 !important;
        /* padding-bottom: 0 !important; */
    }
}

/* Island that has no margin except for tablet and a border too. */
@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .island--tablet-border {
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 32px;
    }
}


@media only screen and (min-width: 672px) and (max-width: 1024px) {
    .hide-on-tablet {
        display: none;
    }
}


@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .island--not-tablet {
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: 1024px) {
    .island--wine-and-culture {
        margin-bottom: 10px;
    }
}


/* No border on tablet or mobile */
@media only screen and (max-width: 1023px) {
    .island--no-border-on-tablet-mobile {
        border-bottom: none;
    }
}


@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .island--dr-vinny {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .island--dr-vinny--q-a {
        margin-bottom: 5px;
    }
}

/* No Border on tablet */
@media only screen and (min-width: 672px) {
    .island--no-mobile-border {
        border-bottom: none;
    }
}

@media only screen and (max-width: 671px) {
    .collecting-fix {
        margin-bottom: 16px;
    }
}


@media only screen and (max-width: 671px) {
    .no-mobile-border-or-spacing {
        border-bottom: none !important;
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .island--tablet-border-again {
        border-bottom: 1px solid #D8D8D8;
    }
}

@media only screen and (max-width: 671px) {
    .island-mobile-only {
        margin-bottom: 24px;
    }
}


@media screen and (max-width: 671px) {
    .no-margin-mobile {
        margin-bottom: 0;
    }
}


@media only screen and (min-width: 672px) and (max-width: 1023px) {
    .hide-just-on-tablet {
        display: none !important;
    }
}


.visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

@media screen and (max-width: 671px) {
    .tweak-rating-homepage .ratings-search {
        margin-bottom: 0;
    }
}


@media screen and (max-width: 671px) {
    .temp-know-your-grapes .card__buffer {
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;
    }

    .temp-know-your-grapes .card.cell .card__title {
        margin-bottom: 0 !important;
    }

}
