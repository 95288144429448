@media print{

    html,
    body {
        font-family: Georgia, serif;
        font-size: 11.75pt;
        line-height: 1.35;
        background: none;
        color: black;
    }

    .site-header,
    .site-navigation,
    .header-search,
    .ad-strip,
    .article__body-tags,
    .article__sidebar,
    .article__related,
    .article__most-popular,
    .footer-newsletter,
    .applications,
    .applications__item,
    .site-footer,
    .walkthru,
    .sf-toolbarreset {
        display: none !important;
    }

    .main-layout {
        padding: 1em 0;
        margin: 0;
        display: block;
    }

    .article__body {
        width: 90%;
        margin: 0 auto;
        display: block;
    }

    .article__body-content {
        overflow: visible !important;
        display: block;
    }

    .article__body-content a:after,
    .article__body-package a:after {
        content: " [" attr(href) "] ";
    }

    
}