/* ======== SITE-SEARCH FOR SEARCH-SOLR-RESULTS.TWIG TEMPLATE ======== */

.site-search__form {
    margin: 0 0 2.4rem;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    .site-search__form-controls {
        order: 1;
        margin: 0 0 2.2rem;
        width: 100%;
        @media only screen and (min-width: 767px) {
            margin-bottom: 4.7rem;
        }
    }
    .site-search__wine-ratings {
        order: 3;
        @media only screen and (min-width: 767px) {
            order: 2;
        }
    }
    .site-search__info {
        order: 2;
        margin: 0 0 2.4rem;
        width: 100%;
        @media only screen and (min-width: 767px) {
            order: 3;
            margin-bottom: 0;
        }
    }
}



.site-search__form-group {
    display: flex;
    flex-flow: row nowrap;
}

.site-search__form-group input[type="search"] {
    margin: 0;
    padding: 1.6rem 2.4rem;
    width: 84%;
    height: 4.8rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    color: #262626;
    -webkit-appearance: none; /* resetting for proper rendering on mobile */
    border: 1px solid #8D0004;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    @media only screen and (min-width: 767px) {
        height: 5.4rem;
        width: 93%;
    }
}

.site-search__form-group button[type="submit"] {
    background-color: #8D0004;
    color: white;
    height: 4.8rem;
    border: 1px solid #8D0004;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 16%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    @media only screen and (min-width: 767px) {
        height: 5.4rem;
        width: 7%;
    }
}

.site-search__filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 1.5rem 0 0;
}

.filter-heading {
    display: none;

    @media only screen and (min-width: 767px) {
        display: inline-block;
        margin-right: 1.2rem;
        font-family: "Open Sans", sans-serif;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: #262626;
    }
}

.filter-checkbox {
    margin: 0 1.6rem 1.6rem 0;
    padding: 0;
    @media only screen and (min-width: 767px) {
        margin-bottom: 0;
    }
}

.filter-checkbox:last-of-type {
    margin-right: 0;
}


/* ======== CUSTOM CHECKBOXES ======== */
.filter-checkbox input[type="checkbox"]:not(:checked),
.filter-checkbox input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.filter-checkbox input[type="checkbox"]:not(:checked) + label,
.filter-checkbox input[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    font-size: 1.675rem;
    cursor: pointer;
}

.filter-checkbox input[type="checkbox"]:not(:checked) + label:before,
.filter-checkbox input[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #262626;
    background-color: #fff;
    border-radius: 3px;
}

.filter-checkbox input[type="checkbox"]:not(:checked) + label:after,
.filter-checkbox input[type="checkbox"]:checked + label:after {
    content: '\2713';
    font-size: 1.85rem;
    position: absolute;
    top: -3px;
    left: 2px;
    width: 16px;
    height: 16px;
    color: #fff;
    transition: all .2s;
}

.filter-checkbox input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

.filter-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

.filter-checkbox input[type="checkbox"]:checked + label:before {
    top: 0;
    left: 0;
    background-color: #404040;
    width: 16px;
    height: 16px;
}

.filter-checkbox input[type="checkbox"]:checked:focus + label:before,
.filter-checkbox input[type="checkbox"]:not(:checked):focus + label:before {
    outline: rgba(0, 103, 244, 0.247) auto 5px;
}








.site-search__message {
    font-family: "Lora", serif;
    font-size: 2.4rem;
    line-height: 2.7rem;
    font-weight: 700;
    color: #262626;
    margin: 0 0 0.4rem;
    padding: 0;
    @media only screen and (min-width: 767px) {
        margin-top: 2.4rem;
        font-size: 3.6rem;
        line-height: 5.3rem;
    }
}

.site-search__results-info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    color: #262626;
    font-weight: 700;
    padding: 0 0 0.6rem;
    border-bottom: 1px solid transparent;
    @media only screen and (min-width: 767px) {
        flex-flow: row nowrap;
        border-color: #c4c4c4;
    }
}

.site-search__results-totals {
    font-family: "Lora", serif;
    font-size: 1.6rem;
    line-height: 2.3rem;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 9.5px;
    margin-bottom: 9.5px;
    @media only screen and (min-width: 767px) {
        border-color: transparent;
        padding-bottom: 0;
        margin-bottom: 0;
        font-size: 2rem;
        line-height: 2.8rem;
    }
}

.site-search__results-sort {
    font-family: "Open Sans", sans-serif;
    font-size: 1.4rem;
    line-height: 2.4rem;
    @media only screen and (min-width: 767px) {
        font-size: 1.6rem;
    }
}

.site-search__results-sort select {
    font-weight: bold;
    border-color: transparent;
    background-color: white;
}


/* ======== RESULTS ======== */
.site-search__result {
    margin: 0 0 1.65rem;
    padding: 0 0 1.7rem;
    border-bottom: 1px solid #979797;
}

.site-search__result:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
}

.site-search__result-type {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #8D0004;
    margin: 0;
    padding: 0;
}

.site-search__result-title {
    margin: 0.8rem 0 0;
    padding: 0;
    font-family: "Lora", serif;
    font-size: 1.6rem;
    line-height: 2.3rem;
    @media only screen and (min-width: 767px) {
        font-size: 2.4rem;
        line-height: 3.5rem;
    }
    a {
        text-decoration: none;
        color: #262626;
    }
}


.site-search__result-blurb {
    font-family: "Open Sans", sans-serif;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: #404040;
    margin: 0.4rem 0 0;
    padding: 0;
    @media only screen and (min-width: 767px) {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
}

.site-search__result-date {
    font-family: "Open Sans", sans-serif;
    font-size: 1.3rem;
    font-weight: normal;
    line-height: 1.8rem;
    color: #666666;
    margin: 1.2rem 0 0;
    padding: 0;
}




.site-search__wine-ratings {
    padding: 20px;
    margin: 0;
    width: calc(100% - 40px);
    background-color: #F3F3F3;

    @media only screen and (min-width: 767px) {
        padding: 32px;
        width: calc(100% - 64px);
    }

    &-header {
        margin: 0 0 1.6rem;
        text-transform: capitalize;
        font-family: "Lora", serif;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 700;
        text-decoration: underline;
        @media only screen and (min-width: 767px) {
            font-size: 2rem;
            line-height: 2.8rem;
        }
    }
    .site-search__result {
        padding-bottom: 16.5px;
        margin-bottom: 16.5px;
    }
    .site-search__result:last-of-type {
        border-bottom: 1px solid #979797;
        margin-bottom: 18.5px;
    }
    .site-search__result-title {
        margin-top: 0;
    }
    .site-search__result-blurb a {
        color: #404040;
        text-decoration: none;
    }
    .site-search__wine-ratings-see-all {
        font-weight: 600;
        text-decoration: none;
        line-height: 2.8rem;
    }
}